import React, { useRef } from 'react';
import "./Hiagram.scss";
import { Box, Grid, Paper, Slide, Typography, Skeleton, Stack, CircularProgress, Fade, IconButton, Alert, FormControl, Autocomplete, TextField, Button, Avatar, Tooltip, InputAdornment, Backdrop, DialogTitle, DialogContentText, DialogActions } from '@mui/material';
import Asset from '../../assets/icons/assets.svg'
import TimeIcon from '../../assets/icons/time-icon.svg'
import Document from '../../assets/icons/doc.svg'
import Event from '../../assets/icons/events.svg'
import TRG from '../../assets/icons/training.svg'
import People from '../../assets/icons/person-icon.svg'
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useContext, useEffect, useState } from "react";
import Hint from '../../assets/icons/hint.svg';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ProjectIcon from '../../assets/icons/card-project-icon.svg';
import TeamIcon from '../../assets/icons/card-team-icon.svg';
import ActivityIcon from '../../assets/icons/card-role-icon.svg';
import ProcessIcon from '../../assets/icons/card-process-icon.svg';
import NodeComponent from '../../Components/AnswerType/NodeComponent/NodeComponent';
import { TransitionProps } from '@mui/material/transitions';
import Card from '@mui/material/Card';
import { ReactFlowProvider, NodeProps } from "reactflow";
import 'reactflow/dist/style.css';
import HiagramFlowComponent from '../../Components/ReactFlow/HiagramFlow';
import { PreferencesContext } from '../../PreferenceContext';
import { GetFindUserForShare, GetHiagramData, GetHiagramDetailsForPdf, GetSharedHiagramData } from '../../services/HiagramService';
import { AlertType } from '../../shared/AlertType';
import { GetHiagramStakeList } from '../../services/StakeService';
import { GetHiagramDocList } from '../../services/DocService';
import { GetHiagramHintList } from '../../services/HintService';
import { GetHiagramTrgList } from '../../services/TrgService';
import { GetHiagramAssetList } from '../../services/AssetService';
import { GetHiagramActionList } from '../../services/ActionService';
import { GetHiagramEventList } from '../../services/EventService';
import { GetHiagramStepList } from '../../services/StepService';
import ItemActionAdd from '../../Components/AnswerType/ItemActionAdd/ItemActionAdd';
import ItemAssetAdd from '../../Components/AnswerType/ItemAssetAdd/ItemAssetAdd';
import ItemDocAdd from '../../Components/AnswerType/ItemDocAdd/ItemDocAdd';
import ItemHintAdd from '../../Components/AnswerType/ItemHintAdd/ItemHintAdd';
import ItemStakeAdd from '../../Components/AnswerType/ItemStakeAdd/ItemStakeAdd';
import ItemTRGAdd from '../../Components/AnswerType/ItemTrgAdd/ItemTrgAdd';
import ItemEventAdd from '../../Components/AnswerType/ItemEventAdd/ItemEventAdd';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { LoadingButton } from '@mui/lab';
import userImage from '../../assets/icons/inviteUserImage.png'
import { CreateShareHiagram, IncrementPdfDownloadCount } from '../../services/ShareHiagramService';
import { EncryptDecryptService } from '../../services/common/EncryptionService';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Nav from '../../navigations/Nav';
import { Context } from '../../services/models/Context';
import ExportToPdf from '../../services/common/exportTopdf/ExportToPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TeamGetAllDetail } from '../../services/TeamService';
import { CUR_ENV } from '../../services/common/const';
import useNodeOpenMenu from '../../hooks/useNodeOpenMenu';
import { Height } from '@mui/icons-material';

enum NodeColour {
    Hiagram = "#680001",
    Team = "#4FA79B",
    Project = "#091C69",
    Role = "#F58A80", //4D81B4
    Activity = "#4D81B4", //F58A80
    Process = "#A7679B"
}

type NodeData = {
    dataId: string;
    name: string;
    type: string;
    isHover: boolean;
    isAccess: boolean;
    colourType: string;
    data?: any;
    roles?: any;
    children?: any;
};

const hiagraminitAllData = {
    teams: 0,
    projects: 0,
    roles: 0,
    activities: 0,
    processes: 0
};

// const initMenuItems = [
//     { id: 1, label: 'Snow', iconSrc: eye },
//     { id: 2, label: 'Lannister', iconSrc: eye },
//     { id: 3, label: 'Lannister', iconSrc: eye },
//     { id: 4, label: 'Stark', iconSrc: eye },
// ];
// const initQuestionsData = [
//     { id: 1, number: 3, iconSrc: Action },
//     { id: 2, number: 4, iconSrc: Document },
//     { id: 3, number: 5, iconSrc: Event },
//     { id: 4, number: 6, iconSrc: Action },
//     { id: 5, number: 7, iconSrc: TRG },
//     { id: 6, number: 8, iconSrc: Event },
//     { id: 7, number: 9, iconSrc: Action },
// ];

// const hiagramNodeComponentConfig = {
//     questionName: "roles",
//     questionTypeCount: "3",
//     upperIcon: BriefCase,
//     isAddButton: true,
//     isUpperLabelVisible: true,
//     isUpperLabelGridVisible: true,
//     isBottomLabelVisible: true,
//     isHoverable: true,
//     isPopoverHover: false,
//     isMenuButtonVisible: true,
//     hoverIconOne: Asset,
//     hoverIconTwo: Hint,
//     hoverIconThree: Hint,
//     hoverIconFour: People,
//     menuItems: initMenuItems,
//     popoverSteptitle: "Contrary to popular belief",
//     popoverDescription: "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia",
//     questionsData: initQuestionsData,
// };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const curQuestionInit = {
    number: 0,
    uid: 0,
}

const formInitialValues = {
    name: null
};

export default function Hiagram() {
    let nodeinitialData: NodeData = {
        dataId: '0',
        name: "Hiagram",
        type: "Hiagram",
        isHover: false,
        isAccess: false,
        colourType: NodeColour.Hiagram,
        data: {
            stakes: 0,
            docs: 0,
            hints: 0,
            trgs: 0,
            assets: 0,
            actions: 0,
            events: 0,
            steps: 0,
        },
        children: [],
    }
    const [updatedHiagramName, setUpdatedHiagramName] = useState('')
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isPaidUser, setIsPaidUser] = useState(false);
    const [isFreeUser, setIsFreeUser] = useState(false);
    const [isPdfLoading, setPdfLoading] = useState(false);
    const [generatedLinkWithLogin, setGeneratedLinkWithLogin] = useState('');
    const [generatedLinkWithoutLogin, setGeneratedLinkWithoutLogin] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [shareFormValue, setShareFormValue] = useState(formInitialValues);
    const [inputValue, setInputValue] = useState('');
    const [isShowLoder, setShowLoder] = React.useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        type: "",
        message: "",
    });
    const [dataLoding, setDataLoding] = useState(false);
    // const [findUserData, setFindUserData] = useState([]);
    const [userValue, setUserValue] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    // const [hiagramNodeComponentConfigData, sethiagramNodeComponentConfigData] = useState(hiagramNodeComponentConfig);
    const [hiagramNodeData, setHiagramNodeData] = useState(nodeinitialData);
    const [hiagramAllData, setHiagramAllData] = useState(hiagraminitAllData);
    const [sharedHiagramData, setSharedHiagramData] = useState(false);

    const [showHiagram, setShowHiagram] = useState(false);
    const [showHiagramDialog, setShowHiagramDialog] = useState(-1);
    const { innerHeight: windowHeight } = window;
    const [flowDivHeight, setFlowDivHeight] = useState((windowHeight - 195) + "px");

    const [openDialogBoxAction, setopenDialogBoxAction] = useState(false);
    const childIteamAddActionCompRef: any = useRef();
    const [openDialogBoxAsset, setopenDialogBoxAsset] = useState(false);
    const childIteamAddAssetCompRef: any = useRef();

    const [openDialogBoxDocument, setopenDialogBoxDocument] = useState(false);
    const childIteamAddDocCompRef: any = useRef();

    const [openDialogBoxHint, setopenDialogBoxHint] = useState(false);
    const childIteamAddHintCompRef: any = useRef();

    const [openDialogBoxPeople, setOpenDialogBoxPeople] = useState(false);
    const childIteamAddStakeCompRef: any = useRef();

    const [openDialogBoxTrg, setopenDialogBoxTrg] = useState(false);
    const childIteamAddTrgCompRef: any = useRef();

    const [openDialogBoxEvent, setopenDialogBoxEvent] = useState(false);
    const childIteamAddEventCompRef: any = useRef();

    const [error, setError] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const [hiagramAllDetails, setHiagramAllDetails] = useState<any>();

    const linkBoxRef: any = useRef(null);
    const validateSchema = Yup.object().shape({
        name: Yup.object().required("User not found - contact administrator.").nullable(),
    });


    const edService = new EncryptDecryptService();

    const handleClickEventOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseEvent = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    let navigate = useNavigate();

    function gotoQuestions() {
        navigate("/question/" + userContext.hiagramId);
    }

    async function hiagramShareTo() {
        if (userContext.clientIsFree === true) {
            setIsFreeUser(true);
            let higramId = window.location.pathname.split("/")[2];
            let curUrlOrigin = window.location.origin
            let freeClientId = 0;
            let curLoginUser = userContext.userId
            if (CUR_ENV === "LOCAL") {
                freeClientId = 14;
            }
            else if (CUR_ENV === "PROD") {
                freeClientId = 34;
            }
            else {
                freeClientId = 15;
            }
            let firstStringEncrypt: any = edService.encrypt(userContext.email);
            let encHiahramId: any = edService.encrypt(higramId);
            let encCurrentClientId: any = edService.encrypt(freeClientId);
            let encCurLoginUserId: any = edService.encrypt(curLoginUser);
            let higramGenratedLink = `${curUrlOrigin}/hiagram/share?u=${encodeURIComponent(firstStringEncrypt)}&h=${encodeURIComponent(encHiahramId)}&cc=${encodeURIComponent(encCurrentClientId)}&&cu=${encodeURIComponent(encCurLoginUserId)}`;


            setGeneratedLinkWithLogin(higramGenratedLink);

        }
        else {
            setIsPaidUser(true);
        }
    }

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let urlParams = useQuery();

    const handleClose = () => {
        setShareFormValue(formInitialValues);
        setInputValue("");
        setIsPaidUser(false);
    };

    const handleFreeUserDialogClose = () => {
        setIsFreeUser(false);
        // setIsPublicAccess(false);
        setGeneratedLinkWithLogin('');
        setGeneratedLinkWithoutLogin('');
        setAlertMessage({
            isSuccess: false,
            type: '',
            message: '',
        });
    }

    async function copyLink(higramLink: any) {
        navigator.clipboard.writeText(higramLink);
        // linkBoxRef.current?.focus();
        linkBoxRef.current?.setSelectionRange(0, 0);
        linkBoxRef.current?.focus();
        setIsCopied(true);
    }

    async function fetchData(hiagramId: any) {
        try {
            const initCountData = {
                teams: 0,
                projects: 0,
                roles: 0,
                activities: 0,
                processes: 0
            };

            let teamstakecount = 0;
            let activitystakecountforteam = 0;


            let params = new URLSearchParams();
            params.append("huid", hiagramId.toString());
            let [hiagram, stakes, docs, hints, trgs, assets, actions, events, steps]: [any, any, any, any, any, any, any, any, any] = await Promise.all([
                GetHiagramData(hiagramId),
                GetHiagramStakeList(params),
                GetHiagramDocList(params),
                GetHiagramHintList(params),
                GetHiagramTrgList(params),
                GetHiagramAssetList(params),
                GetHiagramActionList(params),
                GetHiagramEventList(params),
                GetHiagramStepList(params)
            ]);
            let stakeList: any[] = [];
            let docList: any[] = [];
            let hintList: any[] = [];
            let trgList: any[] = [];
            let assetList: any[] = [];
            let actionList: any[] = [];
            let eventList: any[] = [];
            let stepList: any[] = [];
            let projStakeCount: any[] = [];
            if (stakes !== null && stakes.data !== null && stakes.data.data !== null) {
                stakeList = stakes.data.data;
            }
            if (docs !== null && docs.data !== null && docs.data.data !== null) {
                docList = docs.data.data;
            }
            if (hints !== null && hints.data !== null && hints.data.data !== null) {
                hintList = hints.data.data;
            }
            if (trgs !== null && trgs.data !== null && trgs.data.data !== null) {
                trgList = trgs.data.data;
            }
            if (assets !== null && assets.data !== null && assets.data.data !== null) {
                assetList = assets.data.data;
            }
            if (actions !== null && actions.data !== null && actions.data.data !== null) {
                actionList = actions.data.data;
            }
            if (events !== null && events.data !== null && events.data.data !== null) {
                eventList = events.data.data;
            }
            if (steps !== null && steps.data !== null && steps.data.data !== null) {
                stepList = steps.data.data;
            }


            if (hiagram !== null && hiagram.data !== null && hiagram.data.data !== null) {
                let hiagramData = hiagram.data.data;
                let distinctAssetList: any = [];
                assetList.map((item) => {
                    let index = distinctAssetList.find((x: any) => x.asset_uid === item.asset_uid);
                    if (index === undefined) {
                        distinctAssetList.push(item);
                    }
                    return item;
                });

                let distinctStakeList: any = [];
                stakeList.map((item) => {
                    let index = distinctStakeList.find((x: any) => x.stake_uid === item.stake_uid);
                    if (index === undefined) {
                        distinctStakeList.push(item);
                    }
                    return item;
                });

                let distinctDocList: any = [];
                docList.map((item) => {
                    let index = distinctDocList.find((x: any) => x.doc_uid === item.doc_uid);
                    if (index === undefined) {
                        distinctDocList.push(item);
                    }
                    return item;
                });

                let distinctHintList: any = [];
                hintList.map((item) => {
                    let index = distinctHintList.find((x: any) => x.hint_uid === item.hint_uid);
                    if (index === undefined) {
                        distinctHintList.push(item);
                    }
                    return item;
                });

                let distinctTrgList: any = [];
                trgList.map((item) => {
                    let index = distinctTrgList.find((x: any) => x.trg_uid === item.trg_uid);
                    if (index === undefined) {
                        distinctTrgList.push(item);
                    }
                    return item;
                });

                let distinctEventList: any = [];
                eventList.map((item) => {
                    let index = distinctEventList.find((x: any) => x.event_uid === item.event_uid);
                    if (index === undefined) {
                        distinctEventList.push(item);
                    }
                    return item;
                });


                let distinctActionList: any = [];
                actionList.map((item) => {
                    let index = distinctActionList.find((x: any) => x.action_uid === item.action_uid);
                    if (index === undefined) {
                        distinctActionList.push(item);
                    }
                    return item;
                });

                let nodeData: NodeData = {
                    dataId: '0',
                    name: userContext.hiagramName,
                    type: "Hiagram",
                    isHover: false,
                    isAccess: false,
                    colourType: NodeColour.Hiagram,
                    data: {
                        stakes: distinctStakeList.length,
                        docs: distinctDocList.length,
                        hints: distinctHintList.length,
                        trgs: distinctTrgList.length,
                        assets: distinctAssetList.length,
                        actions: distinctActionList.length,
                        events: distinctEventList.length,
                        steps: stepList.length,
                    },
                    children: [],
                }
                let teamProjData = [];
                if (hiagramData.team !== null && hiagramData.team.length > 0) {
                    teamProjData = hiagramData.team;
                    initCountData.teams = hiagramData.team.length;
                }
                if (hiagramData.proj !== null && hiagramData.proj.length > 0) {
                    teamProjData = [...teamProjData, ...hiagramData.proj];
                    initCountData.projects = hiagramData.proj.length;
                }
                if (teamProjData !== null && teamProjData.length > 0) {
                    teamProjData.forEach((teamProj: any) => {

                        let teamNodeData: NodeData;
                        if (teamProj.is_proj.toString() === "1") {
                            let stakeCount = stakeList.filter((stake: any) => stake.append_to_type === "PROJ" && stake.append_uid === teamProj.uid).length;
                            let docCount = docList.filter((doc: any) => doc.append_to_type === "PROJ" && doc.append_uid === teamProj.uid).length;
                            let hintCount = hintList.filter((hint: any) => hint.append_to_type === "PROJ" && hint.append_uid === teamProj.uid).length;
                            let actionCount = actionList.filter((action: any) => action.append_to_type === "PROJ" && action.append_uid === teamProj.uid).length;

                            // hp code start 12/06
                            let roles = [];
                            if (hiagramData.role !== null && hiagramData.role.length > 0) {
                                roles = hiagramData.role.filter((role: any) => role.proj_uid === teamProj.uid).map((role: any) => ({
                                    roleId: role.uid,
                                    roleName: role.name,
                                    teamProjId: teamProj.uid,
                                    isProj: teamProj.is_proj.toString() === "1",
                                    teamProjPurpose: teamProj.purpose,
                                }));
                            }
                            // hp code end 12/06
                            teamNodeData = {
                                dataId: "P-" + teamProj.uid,
                                name: teamProj.name,
                                type: "Project",
                                isHover: true,
                                isAccess: userContext.isEditAccess,
                                colourType: NodeColour.Project,
                                data: {
                                    // stakes: +userContext.tempUid === 3 ? combinedStakeCount : stakeCount,
                                    //  rollup temp close start
                                    // stakes: stakeCount,
                                    //  rollup temp close end
                                    docs: docCount,
                                    hints: hintCount,
                                    trgs: -1,
                                    assets: -1,
                                    actions: actionCount,
                                    events: -1,
                                    steps: -1,
                                    description: "",
                                    roles: roles,
                                },

                                children: [],
                            }
                        } else {
                            let stakeCount = stakeList.filter((stake: any) => stake.append_to_type === "TEAM" && stake.append_uid === teamProj.uid).length;
                            let docCount = docList.filter((doc: any) => doc.append_to_type === "TEAM" && doc.append_uid === teamProj.uid).length;
                            let hintCount = hintList.filter((hint: any) => hint.append_to_type === "TEAM" && hint.append_uid === teamProj.uid).length;

                            // hp code start 12/06
                            let roles = [];
                            if (hiagramData.role !== null && hiagramData.role.length > 0) {
                                roles = hiagramData.role.filter((role: any) => role.team_uid === teamProj.uid).map((role: any) => ({
                                    roleId: role.uid,
                                    roleName: role.name,
                                    teamProjId: teamProj.uid,
                                    isProj: teamProj.is_proj.toString() === "1",
                                    teamProjPurpose: teamProj.purpose,
                                }));
                            }
                            // hp code end 12/06

                            teamNodeData = {
                                dataId: "T-" + teamProj.uid,
                                name: teamProj.name,
                                type: "Team",
                                isHover: true,
                                isAccess: userContext.isEditAccess,
                                colourType: NodeColour.Team,
                                data: {
                                    // stakes: +userContext.tempUid === 3 ? combinedStakeCount : stakeCount,
                                    //  rollup temp close start
                                    // stakes: stakeCount,
                                    //  rollup temp close end
                                    docs: docCount,
                                    hints: hintCount,
                                    trgs: -1,
                                    assets: -1,
                                    actions: -1,
                                    events: -1,
                                    steps: -1,
                                    description: teamProj.description,
                                    roles: roles,
                                },
                                children: [],
                            }
                        }
                        if (hiagramData.role !== null && hiagramData.role.length > 0) {
                            initCountData.roles = hiagramData.role.length;
                            let roles = [];
                            if (teamProj.is_proj.toString() === "1") {
                                roles = hiagramData.role.filter((role: any) => role.proj_uid === teamProj.uid);
                            } else {
                                roles = hiagramData.role.filter((role: any) => role.team_uid === teamProj.uid);
                            }
                            if (roles.length > 0) {
                                roles.forEach((role: any) => {
                                    //let stakeCount = stakeList.filter((stake: any) => stake.append_to_type === "ROLE" && stake.append_uid === role.uid).length;
                                    let docCount = docList.filter((doc: any) => doc.append_to_type === "ROLE" && doc.append_uid === role.uid).length;
                                    //let hintCount = hintList.filter((hint: any) => hint.append_to_type === "ROLE" && hint.append_uid === role.uid).length;
                                    //let trgCount = trgList.filter((trg: any) => trg.append_to_type === "ROLE" && trg.append_uid === role.uid).length;
                                    let parentData: any = {
                                        teamProjName: teamProj.name,
                                        teamProjId: teamProj.uid,
                                        isProj: teamProj.is_proj.toString() === "1" ? "true" : "false",
                                        teamProjPurpose: teamProj.purpose
                                    }
                                    let roleNodeData: NodeData = {
                                        dataId: "R-" + role.uid,
                                        name: role.name,
                                        type: "Role",
                                        isHover: true,
                                        isAccess: userContext.isEditAccess,
                                        colourType: NodeColour.Role,
                                        data: {
                                            stakes: -1,
                                            docs: docCount,
                                            hints: -1,
                                            trgs: -1,
                                            assets: -1,
                                            actions: -1,
                                            events: -1,
                                            steps: -1,
                                            description: role.description,
                                            parentData: parentData
                                        },
                                        children: [],
                                    }
                                    if (hiagramData.activity !== null && hiagramData.activity.length > 0) {
                                        initCountData.activities = hiagramData.activity.length;
                                        let activities = hiagramData.activity.filter((activity: any) => activity.role_uid === role.uid);
                                        if (activities !== null && activities.length > 0) {
                                            activities.forEach((activity: any) => {
                                                let stakeCount = stakeList.filter((stake: any) => stake.append_to_type === "ACTIVITY" && stake.append_uid === activity.uid).length;
                                                let docCount = docList.filter((doc: any) => doc.append_to_type === "ACTIVITY" && doc.append_uid === activity.uid).length;
                                                let hintCount = hintList.filter((hint: any) => hint.append_to_type === "ACTIVITY" && hint.append_uid === activity.uid).length;
                                                let trgCount = trgList.filter((trg: any) => trg.append_to_type === "ACTIVITY" && trg.append_uid === activity.uid).length;
                                                let assetCount = assetList.filter((asset: any) => asset.append_to_type === "ACTIVITY" && asset.append_uid === activity.uid).length;
                                                let actionCount = actionList.filter((action: any) => action.append_to_type === "ACTIVITY" && action.append_uid === activity.uid).length;
                                                let eventCount = eventList.filter((event: any) => event.append_to_type === "ACTIVITY" && event.append_uid === activity.uid).length;
                                                let parentData: any = {
                                                    teamProjName: teamProj.name,
                                                    teamProjId: teamProj.uid,
                                                    isProj: teamProj.is_proj.toString() === "1" ? "true" : "false",
                                                    teamProjPurpose: teamProj.purpose,
                                                    roleId: role.uid,
                                                    roleName: role.name
                                                }
                                                let activityNodeData: NodeData = {
                                                    dataId: "A-" + activity.uid,
                                                    name: activity.name,
                                                    type: "Activity",
                                                    isHover: true,
                                                    isAccess: userContext.isEditAccess,
                                                    colourType: NodeColour.Activity,
                                                    data: {
                                                        stakes: stakeCount,
                                                        docs: docCount,
                                                        hints: hintCount,
                                                        trgs: trgCount,
                                                        assets: assetCount,
                                                        actions: actionCount,
                                                        events: eventCount,
                                                        steps: -1,
                                                        description: activity.description,
                                                        parentData: parentData
                                                    },
                                                    children: [],
                                                }
                                                if (hiagramData.cess !== null && hiagramData.cess.length > 0) {
                                                    initCountData.processes = hiagramData.cess.length;
                                                    let cesses = hiagramData.cess.filter((cess: any) => cess.activity_uid === activity.uid);
                                                    if (cesses !== null && cesses.length > 0) {
                                                        cesses.forEach((cess: any) => {
                                                            let docCount = docList.filter((doc: any) => doc.append_to_type === "CESS" && doc.append_uid === cess.uid).length;
                                                            let trgCount = trgList.filter((trg: any) => trg.append_to_type === "CESS" && trg.append_uid === cess.uid).length;
                                                            let stepCount = stepList.filter((step: any) => step.cess_uid === cess.uid).length;
                                                            let parentData: any = {
                                                                teamProjName: teamProj.name,
                                                                teamProjId: teamProj.uid,
                                                                isProj: teamProj.is_proj.toString() === "1" ? "true" : "false",
                                                                teamProjPurpose: teamProj.purpose,
                                                                roleId: role.uid,
                                                                roleName: role.name,
                                                                activityId: activity.uid,
                                                                activityName: activity.name
                                                            }
                                                            let cessNodeData: NodeData = {
                                                                dataId: "C-" + cess.uid,
                                                                name: cess.name,
                                                                type: "Process",
                                                                isHover: true,
                                                                isAccess: userContext.isEditAccess,
                                                                colourType: NodeColour.Process,
                                                                data: {
                                                                    stakes: -1,
                                                                    docs: docCount,
                                                                    hints: -1,
                                                                    trgs: trgCount,
                                                                    assets: -1,
                                                                    actions: -1,
                                                                    events: -1,
                                                                    steps: stepCount,
                                                                    description: cess.description,
                                                                    parentData: parentData
                                                                },
                                                                children: [],
                                                            }
                                                            activityNodeData.children.push(cessNodeData);
                                                        });
                                                    }
                                                }
                                                // roleNodeData.children.push(activityNodeData);
                                                teamNodeData.children.push(activityNodeData);
                                            });
                                        }
                                    }
                                });
                            }
                        }
                        nodeData.children.push(teamNodeData);
                    });
                }
                setHiagramAllData(initCountData);
                setSharedHiagramData(false);
                setHiagramNodeData(nodeData);
                if (nodeData.children.length <= 0) {
                    setShowHiagramDialog(0);
                }
                //setHiagramData(data[0]);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchCardCount(hiagramId: any) {
        try {
            const initCountData = {
                teams: 0,
                projects: 0,
                roles: 0,
                activities: 0,
                processes: 0
            };

            let params = new URLSearchParams();
            params.append("huid", hiagramId.toString());
            let [hiagram, stakes, docs, hints, trgs, assets, actions, events, steps]: [any, any, any, any, any, any, any, any, any] = await Promise.all([
                GetHiagramData(hiagramId),
                GetHiagramStakeList(params),
                GetHiagramDocList(params),
                GetHiagramHintList(params),
                GetHiagramTrgList(params),
                GetHiagramAssetList(params),
                GetHiagramActionList(params),
                GetHiagramEventList(params),
                GetHiagramStepList(params)
            ]);
            let stakeList: any[] = [];
            let docList: any[] = [];
            let hintList: any[] = [];
            let trgList: any[] = [];
            let assetList: any[] = [];
            let actionList: any[] = [];
            let eventList: any[] = [];
            let stepList: any[] = [];
            if (stakes !== null && stakes.data !== null && stakes.data.data !== null) {
                stakeList = stakes.data.data;
            }
            if (docs !== null && docs.data !== null && docs.data.data !== null) {
                docList = docs.data.data;
            }
            if (hints !== null && hints.data !== null && hints.data.data !== null) {
                hintList = hints.data.data;
            }
            if (trgs !== null && trgs.data !== null && trgs.data.data !== null) {
                trgList = trgs.data.data;
            }
            if (assets !== null && assets.data !== null && assets.data.data !== null) {
                assetList = assets.data.data;
            }
            if (actions !== null && actions.data !== null && actions.data.data !== null) {
                actionList = actions.data.data;
            }
            if (events !== null && events.data !== null && events.data.data !== null) {
                eventList = events.data.data;
            }
            if (steps !== null && steps.data !== null && steps.data.data !== null) {
                stepList = steps.data.data;
            }

            if (hiagram !== null && hiagram.data !== null && hiagram.data.data !== null) {
                let hiagramData = hiagram.data.data;
                let distinctAssetList: any = [];
                assetList.map((item) => {
                    let index = distinctAssetList.find((x: any) => x.asset_uid === item.asset_uid);
                    if (index === undefined) {
                        distinctAssetList.push(item);
                    }
                    return item;
                });

                let distinctStakeList: any = [];
                stakeList.map((item) => {
                    let index = distinctStakeList.find((x: any) => x.stake_uid === item.stake_uid);
                    if (index === undefined) {
                        distinctStakeList.push(item);
                    }
                    return item;
                });

                let distinctDocList: any = [];
                docList.map((item) => {
                    let index = distinctDocList.find((x: any) => x.doc_uid === item.doc_uid);
                    if (index === undefined) {
                        distinctDocList.push(item);
                    }
                    return item;
                });

                let distinctHintList: any = [];
                hintList.map((item) => {
                    let index = distinctHintList.find((x: any) => x.hint_uid === item.hint_uid);
                    if (index === undefined) {
                        distinctHintList.push(item);
                    }
                    return item;
                });

                let distinctTrgList: any = [];
                trgList.map((item) => {
                    let index = distinctTrgList.find((x: any) => x.trg_uid === item.trg_uid);
                    if (index === undefined) {
                        distinctTrgList.push(item);
                    }
                    return item;
                });

                let distinctEventList: any = [];
                eventList.map((item) => {
                    let index = distinctEventList.find((x: any) => x.event_uid === item.event_uid);
                    if (index === undefined) {
                        distinctEventList.push(item);
                    }
                    return item;
                });


                let distinctActionList: any = [];
                actionList.map((item) => {
                    let index = distinctActionList.find((x: any) => x.action_uid === item.action_uid);
                    if (index === undefined) {
                        distinctActionList.push(item);
                    }
                    return item;
                });

                let nodeData: NodeData = {
                    dataId: '0',
                    name: updatedHiagramName,
                    // name: userContext.hiagramName,
                    type: "Hiagram",
                    isHover: false,
                    isAccess: false,
                    colourType: NodeColour.Hiagram,
                    data: {
                        stakes: distinctStakeList.length,
                        docs: distinctDocList.length,
                        hints: distinctHintList.length,
                        trgs: distinctTrgList.length,
                        assets: distinctAssetList.length,
                        actions: distinctActionList.length,
                        events: distinctEventList.length,
                        steps: stepList.length,
                    },
                    children: [],
                }
                // setHiagramAllData(initCountData);
                // setSharedHiagramData(false);
                setHiagramNodeData({
                    ...hiagramNodeData,
                    data: nodeData.data
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function findUser(name: any) {
        setDataLoding(true);
        try {
            let params = new URLSearchParams();
            params.append("cuid", userContext.clientId.toString());
            params.append("uuid", userContext.userId.toString());
            params.append("name", name);
            params.append("limit", "5");
            const findUser: any = await GetFindUserForShare(params);
            if (findUser !== null && findUser.data !== null && findUser.data.data !== null) {
                let userData = findUser.data.data;
                let optionList: any = [];
                userData.forEach((user: any) => {
                    let option = {
                        id: +user.uid,
                        name: user.first_name + " " + user.last_name + " : " + user.email
                    };
                    optionList.push(option);
                });
                setUserValue(optionList);
            }
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setDataLoding(false);
    }

    const handleClickActionNode = () => {
        setopenDialogBoxAction(true);
    };

    const handleClickDocumentNode = () => {
        setopenDialogBoxDocument(true);
    };

    const handleClickAssetNode = () => {
        setopenDialogBoxAsset(true);
    };

    const handleClickHintNode = () => {
        setopenDialogBoxHint(true);
    };

    const handleClickPeopleNode = () => {
        setOpenDialogBoxPeople(true);
    };

    const handleClickTrainingNode = () => {
        setopenDialogBoxTrg(true);
    };

    const handleClickEventNode = () => {
        setopenDialogBoxEvent(true);
    };

    const handleCloseAssetNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxAsset(false);
    };

    const handleCloseActionNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxAction(false);
    };

    const handleCloseDocumentNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxDocument(false);
    };

    const handleCloseHintNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxHint(false);
    };

    const handleClosePeopleNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialogBoxPeople(false);
    };

    const handleCloseTrgNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxTrg(false);
    };

    const handleCloseEventgNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxEvent(false);
    };

    async function submitForm(values: any, resetForm: any) {
        try {
            setBtnLoading(true);
            let requestData = {
                hiagramUid: +userContext.hiagramId,
                clientUid: +userContext.clientId,
                userUid: values.name.id,
                isView: true,
                isEdit: false,
                dataManagedBy: userContext.userId
            }
            let data: any = await CreateShareHiagram(requestData);
            if (data !== null && data.data === null) {
                setAlertMessage({
                    isSuccess: false,
                    type: AlertType.Error,
                    message: data.message,
                });
            } else {
                // setAlertMessage({
                //     isSuccess: true,
                //     type: AlertType.Success,
                //     message: "Hiagram shared!",
                // });
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Hiagram shared!",
                    alertType: AlertType.Success,
                });
                setBtnLoading(false);
                // setOpen(false);
                setIsPaidUser(false);
            }
            // setOpen(false);
            resetForm();
            setInputValue("");
            setBtnLoading(false);

            // setTimeout(() => {
            //     setAlertMessage({
            //         type: "",
            //         isSuccess: false,
            //         message: "",
            //     });
            //     // setOpen(false);
            // }, 5000);
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
            setBtnLoading(false);
        }
    }

    async function fetchPDFData() {
        setShowLoder(true);
        setPdfLoading(true);
        let curRawHiagramId = urlParams.get("h");
        let decHiagramId: any = edService.decrypt(curRawHiagramId);
        try {
            const data = {
                huid: decHiagramId === undefined ? +userContext.hiagramId : decHiagramId,
                cuid: +userContext.clientId,
                uuid: +userContext.userId
            }
            const findUser: any = await GetHiagramDetailsForPdf(data);
            setPdfLoading(false);
            if (findUser !== undefined && findUser !== null && findUser.data !== undefined && findUser.data !== null) {
                setHiagramAllDetails(findUser.data)
            }
        }
        catch (e) {
            setPdfLoading(false);
            console.log("err of pdf details:", e)
        }
        setShowLoder(false);
    }

    async function downloadPdf() {
        let requestData = {
            hiagramUid: +userContext.hiagramId,
            clientUid: +userContext.clientId,
            userUid: +userContext.userId,
            userRole: +userContext.userRole,
            dataManagedBy: userContext.userId
        }
        const data: any = await IncrementPdfDownloadCount(requestData);
    }


    async function fetchShareHiagramData() {
        let curRawEmailId = urlParams.get("u");
        let curRawHiagramId = urlParams.get("h");
        let curRawClientId = urlParams.get("cc");
        let curRawUserId = urlParams.get("cu");
        // let curEmail = userContext.email;
        // let curHiagramId = userContext.hiagramId;
        let curEmail = '';
        let curHiagramId = 0;
        let curClientId = 0;
        let curUserId = 0;
        if (curRawEmailId !== undefined && curRawEmailId !== null && curRawEmailId !== "" && curRawHiagramId !== undefined && curRawHiagramId !== null && curRawHiagramId !== "" && curRawClientId !== undefined && curRawClientId !== null && curRawClientId !== "") {
            let decEmailId: any = edService.decrypt(curRawEmailId);
            let decHiagramId: any = edService.decrypt(curRawHiagramId);
            let decCurrentClientId: any = edService.decrypt(curRawClientId);
            let decCurLoginUserId: any = edService.decrypt(curRawUserId);

            if (decEmailId !== undefined && decEmailId !== null && decEmailId !== "" && curRawHiagramId !== undefined && curRawHiagramId !== null && curRawHiagramId !== "" && decCurrentClientId !== undefined && decCurrentClientId !== null && decCurrentClientId !== "" && decCurLoginUserId !== undefined && decCurLoginUserId !== null && decCurLoginUserId !== "") {
                curEmail = decEmailId;
                curHiagramId = +decHiagramId;
                curClientId = +decCurrentClientId
                curUserId = +decCurLoginUserId
                setDataLoding(true);
                try {
                    let params = new URLSearchParams();
                    params.append("uemail", curEmail);
                    params.append("huid", curHiagramId.toString());
                    params.append("curClientId", curClientId.toString());
                    params.append("curUserId", curUserId.toString());
                    let sharedHiagramData: any = await GetSharedHiagramData(params);
                    if (sharedHiagramData !== undefined && sharedHiagramData !== null && sharedHiagramData !== "" && sharedHiagramData.data.hiagram !== undefined && sharedHiagramData.data.hiagram !== null && sharedHiagramData.data.hiagram !== "") {
                        let hiagramData = sharedHiagramData.data.hiagram.data;
                        let token = sharedHiagramData.data.token
                        setUpdatedHiagramName(hiagramData.hiagram_name)
                        updateUserContext({
                            ...userContext,
                            hiagramId: hiagramData.hiagram_uid,
                            hiagramName: hiagramData.hiagram_name,
                            hiagramType: hiagramData.hiagram_type,
                            hiagramProgress: hiagramData.progress,
                            tempUid: +hiagramData.temp_uid,
                            token: token
                        });
                        setIsPublic(true);
                        // fetchData(curHiagramId);
                    }
                    if (window.location.href.includes("h=") === true) {
                        let curUrlOrigin = window.location.origin;
                        let firstStringEncrypt: any = curRawEmailId;
                        let encHiahramId: any = curRawHiagramId;
                        let encCurrentClientId: any = curRawClientId;
                        let encCurLoginUserId: any = curRawUserId;
                        let higramGenratedLink = `${curUrlOrigin}/hiagram/share?u=${encodeURIComponent(firstStringEncrypt)}&h=${encodeURIComponent(encHiahramId)}&cc=${encodeURIComponent(encCurrentClientId)}&&cu=${encodeURIComponent(encCurLoginUserId)}`;
                        setIsLogin(false);
                        setGeneratedLinkWithoutLogin(higramGenratedLink);
                    }
                    fetchData(curHiagramId);
                    fetchPDFData();

                } catch (e: any) {
                    setError(true);
                }
            }
            else {
                setError(true);
                // navigate("/")
            }
        } else {
            findUser('');
            fetchData(userContext.hiagramId);
            setIsPublic(false);
        }
        setDataLoding(false);
    }

    async function getHiagramLink() {
        if (userContext.clientIsFree === true) {
            // setIsFreeUser(true);
            let higramId = window.location.pathname.split("/")[2];
            let curUrlOrigin = window.location.origin
            let freeClientId = 0;
            let curLoginUser = userContext.userId
            if (CUR_ENV === "LOCAL") {
                freeClientId = 14;
            }
            else if (CUR_ENV === "PROD") {
                freeClientId = 34;
            }
            else {
                freeClientId = 15;
            }

            let firstStringEncrypt: any = edService.encrypt(userContext.email);
            let encHiahramId: any = edService.encrypt(higramId);
            let encCurrentClientId: any = edService.encrypt(freeClientId);
            let encCurLoginUserId: any = edService.encrypt(curLoginUser);
            let higramGenratedLink = `${curUrlOrigin}/hiagram/share?u=${encodeURIComponent(firstStringEncrypt)}&h=${encodeURIComponent(encHiahramId)}&cc=${encodeURIComponent(encCurrentClientId)}&&cu=${encodeURIComponent(encCurLoginUserId)}`;

            if (window.location.href.includes("h=") === false) {
                setIsLogin(true);
                setGeneratedLinkWithLogin(higramGenratedLink);
            }
        }
    }

    useEffect(() => {
        setShowHiagram(false);
        setShowHiagramDialog(-1);
        fetchShareHiagramData();
        fetchPDFData();
        getHiagramLink()

    }, []);

    useEffect(() => {
        if (userContext.isCardCountUpdate === true || userContext.isItemCountUpdated === true) {
            fetchCardCount(userContext.hiagramId);
            fetchData(userContext.hiagramId);
            fetchPDFData();
            updateUserContext({
                ...userContext, isCardCountUpdate: false
            });


        }
    }, [userContext.isCardCountUpdate, userContext.isItemCountUpdated])

    useEffect(() => {
        if (userContext.isDataUpdated === true) {
            setShowHiagram(false);
            fetchData(userContext.hiagramId);
            fetchPDFData();
            updateUserContext({
                ...userContext, isDataUpdated: false
            });


        }
    }, [userContext.isDataUpdated]);

    useEffect(() => {
        if (hiagramNodeData.children.length > 0) {
            setShowHiagram(true);
            setShowHiagramDialog(hiagramNodeData.children.length);
        }
    }, [hiagramNodeData]);

    useEffect(() => {
        if (userContext.hiagramId > 0) {
            fetchData(userContext.hiagramId);
        }

    }, [userContext.hiagramId])

    useEffect(() => {
        if (openDialogBoxAction === true && childIteamAddActionCompRef.current !== undefined && childIteamAddActionCompRef.current !== null) {
            childIteamAddActionCompRef.current.getData();
        } else if (openDialogBoxAsset === true && childIteamAddAssetCompRef.current !== undefined && childIteamAddAssetCompRef.current !== null) {
            childIteamAddAssetCompRef.current.getData();
        }
        else if (openDialogBoxPeople === true && childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null) {
            childIteamAddStakeCompRef.current.getData();
        }
        else if (openDialogBoxDocument === true && childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null) {
            childIteamAddDocCompRef.current.getData();
        }
        else if (openDialogBoxHint === true && childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null) {
            childIteamAddHintCompRef.current.getData();
        }
        else if (openDialogBoxTrg === true && childIteamAddTrgCompRef.current !== undefined && childIteamAddTrgCompRef.current !== null) {
            childIteamAddTrgCompRef.current.getData();
        }
        else if (openDialogBoxEvent === true && childIteamAddEventCompRef.current !== undefined && childIteamAddEventCompRef.current !== null) {
            childIteamAddEventCompRef.current.getData();
        }
    })

    const nodeColor = (node: any) => {
        switch (node.type) {
            case 'initial':
                return '#6ede87';
            case 'output':
                return '#6865A5';
            default:
                return '#ff0072';
        }
    };
    return (
        <>
            {isPublic ? <Nav isHidden={true} /> : <></>}
            <Fade in={true} timeout={{ enter: 500 }}>
                <div className='hiagram-main-div'>
                    {error === true ?
                        <Box className='show-error-msg-box'>
                            <Typography className='show-error-msg-text'>Something went wrong</Typography>
                        </Box> :
                        <>
                            <Box className='box-hiagram'>

                                <Paper elevation={0} className='paper-hiagram'>
                                    <Box className='upper-main-box'>
                                        <Box className='all-type-list-pos'>
                                            <Card className='all-type-list'>
                                                <Box className='card-inner-main-box'>
                                                    <Box className='card-inner-sub-box'>
                                                        {showHiagram === false ? <Skeleton variant="rectangular" animation="wave" sx={{ height: "20px", width: "25px" }} /> : <Box className='card-img-box'><img loading="lazy" alt="imprend" src={ProjectIcon} /></Box>}
                                                        <Box className='card-label-box'>
                                                            {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: "50px" }} /> : <Typography className='label-text'>Projects</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className='card-count-box'>
                                                        {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: "20px" }} /> : <Typography>{hiagramAllData.projects}</Typography>}
                                                    </Box>
                                                </Box>
                                                <Box className='card-inner-main-box'>
                                                    <Box className='card-inner-sub-box'>
                                                        {showHiagram === false ? <Skeleton variant="rectangular" animation="wave" sx={{ height: "20px", width: "25px" }} /> : <Box className='card-img-box'><img loading="lazy" alt="imprend" src={TeamIcon} /></Box>}

                                                        <Box className='card-label-box'>
                                                            {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: "50px" }} /> : <Typography className='label-text'>Teams</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className='card-count-box'>
                                                        {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: "20px" }} /> : <Typography>{hiagramAllData.teams}</Typography>}
                                                    </Box>
                                                </Box>
                                                {/* {+userContext.tempUid === 3 ? <></> : <Box className='card-inner-main-box'>
                                                    <Box className='card-inner-sub-box'>
                                                        {showHiagram === false ? <Skeleton variant="rectangular" animation="wave" sx={{ height: "20px", width: "25px" }} /> : <Box className='card-img-box'><img loading="lazy" alt="imprend" src={RoleIcon} /></Box>}
                                                        <Box className='card-label-box'>
                                                            {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: "50px" }} /> : <Typography>Roles</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className='card-count-box'>
                                                        {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: "20px" }} /> : <Typography>{hiagramAllData.roles}</Typography>}
                                                    </Box>
                                                </Box>} */}
                                                <Box className='card-inner-main-box'>
                                                    <Box className='card-inner-sub-box'>
                                                        {showHiagram === false ? <Skeleton variant="rectangular" animation="wave" sx={{ height: "20px", width: "25px" }} /> : <Box className='card-img-box'><img loading="lazy" alt="imprend" src={ActivityIcon} /></Box>}
                                                        <Box className='card-label-box'>
                                                            {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: "50px" }} /> : <Typography className='label-text'>Roles</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className='card-count-box'>
                                                        {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: "20px" }} /> : <Typography>{hiagramAllData.activities}</Typography>}
                                                    </Box>
                                                </Box>
                                                <Box className='card-inner-main-box'>
                                                    <Box className='card-inner-sub-box'>
                                                        {showHiagram === false ? <Skeleton variant="rectangular" animation="wave" sx={{ height: "20px", width: "25px" }} /> : <Box className='card-img-box'><img loading="lazy" alt="imprend" src={ProcessIcon} /></Box>}
                                                        <Box className='card-label-box'>
                                                            {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: "50px" }} /> : <Typography className='label-text'>Processes</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Box className='card-count-box'>
                                                        {showHiagram === false ? <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: "20px" }} /> : <Typography>{hiagramAllData.processes}</Typography>}
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Box>

                                        <Box className='all-type-count'>
                                            <Grid container className='node-component-container-hiagram'>
                                                <Card
                                                    className='card-component'
                                                    onClick={handleClickActionNode}>
                                                    <NodeComponent
                                                        iconSrc={TimeIcon}
                                                        isLoading={!showHiagram}
                                                        text="Actions"
                                                        number={hiagramNodeData.data.actions}
                                                        border='#fbd5d1'
                                                    />
                                                </Card>
                                                {/* <Card
                                                    className='card-component'
                                                    onClick={handleClickAssetNode}
                                                >
                                                    <NodeComponent
                                                        iconSrc={Asset}
                                                        isLoading={!showHiagram}
                                                        text="Assets"
                                                        number={hiagramNodeData.data.assets}
                                                        border='#6b77a5'
                                                    />
                                                </Card> */}
                                                {/* hp code start */}
                                                {+userContext.tempUid === 3 ? <></> : <Card
                                                    className='card-component'
                                                    onClick={handleClickAssetNode}>
                                                    <NodeComponent
                                                        iconSrc={Asset}
                                                        isLoading={!showHiagram}
                                                        text="Assets"
                                                        number={hiagramNodeData.data.assets}
                                                        border='#6b77a5'
                                                    />
                                                </Card>}
                                                {/* hp code end */}
                                                <Card
                                                    className='card-component'
                                                    onClick={handleClickDocumentNode}
                                                >
                                                    <NodeComponent
                                                        iconSrc={Document}
                                                        isLoading={!showHiagram}
                                                        text="Documents"
                                                        number={hiagramNodeData.data.docs}
                                                        border='#94b3d2'
                                                    />
                                                </Card>

                                                <Card
                                                    className='card-component'
                                                    //onClick={handleClickEventOpen}
                                                    onClick={handleClickEventNode}
                                                >
                                                    <NodeComponent
                                                        iconSrc={Event}
                                                        isLoading={!showHiagram}
                                                        text="Events"
                                                        number={hiagramNodeData.data.events}
                                                        border='#95cac3'
                                                    />
                                                </Card>
                                                <Card
                                                    className='card-component'
                                                    onClick={handleClickHintNode}
                                                >
                                                    <NodeComponent
                                                        iconSrc={Hint}
                                                        isLoading={!showHiagram}
                                                        text="Hints & Risks"
                                                        number={hiagramNodeData.data.hints}
                                                        border='#f59090'
                                                    />
                                                </Card>
                                                <Card
                                                    className='card-component'
                                                    onClick={handleClickPeopleNode}
                                                >
                                                    <NodeComponent
                                                        iconSrc={People}
                                                        isLoading={!showHiagram}
                                                        text="People"
                                                        number={hiagramNodeData.data.stakes}
                                                        border='#807c92'
                                                    />
                                                </Card>
                                                {/* <Card
                                                    className='card-component'
                                                    onClick={handleClickTrainingNode}>
                                                    <NodeComponent
                                                        iconSrc={TRG}
                                                        isLoading={!showHiagram}
                                                        text="Skills"
                                                        number={hiagramNodeData.data.trgs}
                                                        border='#ba6668	'
                                                    />
                                                </Card> */}
                                                {/* hp code start */}
                                                {+userContext.tempUid === 3 ? <></> : <Card
                                                    className='card-component'
                                                    onClick={handleClickTrainingNode}>
                                                    <NodeComponent
                                                        iconSrc={TRG}
                                                        isLoading={!showHiagram}
                                                        text="Skills"
                                                        number={hiagramNodeData.data.trgs}
                                                        border='#ba6668	'
                                                    />
                                                </Card>}
                                                {/* hp code end */}
                                            </Grid>
                                        </Box>

                                        <Box className='hiagram-process-pos'>
                                            <Box className='hiagram-process'>
                                                <div className='hiagram-process-main-div'>
                                                    <Stack spacing={2} direction="row"  >
                                                        <CircularProgress
                                                            variant="determinate"
                                                            className={userContext.hiagramProgress < 100 ? 'blink_progess' : ''}
                                                            value={userContext.hiagramProgress}
                                                            size={140}
                                                            // size={160}
                                                            sx={{ zIndex: 10 }}
                                                            thickness={2} />
                                                        <div className='hiagram-process-backgroud'>
                                                            <CircularProgress
                                                                variant="determinate"
                                                                value={100}
                                                                size={140}
                                                                // size={160}
                                                                sx={{
                                                                    color: 'rgb(238 238 238)'
                                                                }}
                                                                thickness={2} />
                                                        </div>
                                                        <div className='hiagram-process-text'>
                                                            {userContext.hiagramProgress > 0 ?
                                                                <>
                                                                    <label className='label-per'>
                                                                        {userContext.hiagramProgress}%
                                                                    </label>
                                                                    <label className='label-complete'>
                                                                        complete
                                                                    </label>
                                                                </> : <></>}
                                                            {isPublic === true ? <></> :
                                                                <div className='div-label-resume' onClick={gotoQuestions}>
                                                                    <label className='label-resume'>
                                                                        resume
                                                                    </label>
                                                                    <label className='label-resume'>
                                                                        interview
                                                                    </label>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Stack>
                                                </div>
                                                {userContext.isEditAccess === true && !isPublic === true ?
                                                    <button className="hiagram-share-button" onClick={hiagramShareTo}>Share</button> : <></>}
                                                <Box className={isPdfLoading !== true ? "download-pdf-button" : "download-pdf-button blink"}>
                                                    {isPdfLoading === false ?
                                                        <PDFDownloadLink document={
                                                            <ExportToPdf
                                                                hiagramAllDetailsForPdf={hiagramAllDetails}
                                                                hiagramLink={isLogin === true ? generatedLinkWithLogin : generatedLinkWithoutLogin}
                                                            />
                                                        } fileName={`${hiagramAllDetails?.hiagram?.hiagram_name}.pdf`} >
                                                            {({ blob, url, loading, error }) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            loading === false && url !== null && blob !== null && isPdfLoading === false ?
                                                                                <Box sx={{ color: 'white' }}
                                                                                    onClick={downloadPdf}
                                                                                >Download PDF</Box>
                                                                                :
                                                                                <Box sx={{ color: 'white' }}>Loading PDF . . .</Box>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            }
                                                        </PDFDownloadLink>
                                                        :
                                                        <Box sx={{ color: 'white' }}>Loading PDF . . .</Box>
                                                    }

                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <br />

                                    {/* <Grid container spacing={2} >
                                            <Grid >
                                                <HiagramNodeComponent
                                                    iconSrc={BriefCase}
                                                    questionTypeName="Executive"
                                                    questionType="team"
                                                    backgroundColors='#F58A80'
                                                    hiagramNodeComponentConfig={hiagramNodeComponentConfig}
                                                />
                                            </Grid>
                                        </Grid> */}
                                    <Box className="flow-hiagram-builder" style={{ height: flowDivHeight }}>
                                        {showHiagram === true ?
                                            <ReactFlowProvider>
                                                <HiagramFlowComponent isdataUpdate={true} hiagramData={hiagramNodeData} />
                                            </ReactFlowProvider> :
                                            <Box className="flow-builder-loding">
                                                <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: showHiagramDialog === 0 ? '100px' : '200px', width: 'auto' }}></img>
                                            </Box>}
                                    </Box>
                                </Paper>
                            </Box>
                            <Dialog
                                maxWidth="xl"
                                fullWidth={true}
                                open={openDialog}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseEvent}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    {/* <EventTimeLine
                                    onClose={handleCloseEvent} /> */}
                                    {/* <RoleDetail onClose={handleCloseEvent} /> */}
                                    {/* <ActivityDetail onClose={handleCloseEvent} /> */}
                                    {/* <RoleQuickView onClose={handleCloseEvent} /> */}
                                    {/* <ProcessDetail onClose={handleCloseEvent} /> */}
                                </DialogContent>
                            </Dialog>

                            {/* Action */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxAction}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseActionNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemActionAdd onClose={handleCloseActionNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddActionCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* Asset */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxAsset}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseAssetNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemAssetAdd onClose={handleCloseAssetNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddAssetCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* Doc */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxDocument}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseDocumentNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemDocAdd onClose={handleCloseDocumentNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddDocCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* Hint */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxHint}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseHintNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemHintAdd onClose={handleCloseHintNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddHintCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* People */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxPeople}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClosePeopleNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemStakeAdd onClose={handleClosePeopleNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        directlyOpenFromTeamProj={false}
                                        ref={childIteamAddStakeCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* Training */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxTrg}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseTrgNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemTRGAdd onClose={handleCloseTrgNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddTrgCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* Event */}
                            <Dialog
                                maxWidth="md"
                                fullWidth={true}
                                open={openDialogBoxEvent}
                                scroll="body"
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseEventgNode}
                            >
                                <DialogContent
                                    className='dalog-box-content'
                                    sx={{ paddingBottom: "50px" }}>
                                    <ItemEventAdd onClose={handleCloseEventgNode}
                                        QuickView={true}
                                        curQuestion={curQuestionInit}
                                        ref={childIteamAddEventCompRef}
                                    />
                                </DialogContent>
                            </Dialog>

                            {/* add modal start */}

                            <Dialog sx={{
                                "& .MuiDialog-paper": { width: "80%", maxHeight: 455 },
                            }}
                                maxWidth="xs"
                                open={isFreeUser}
                                onClose={handleFreeUserDialogClose}
                                className='share-hiagram-dialog-box'>
                                <Grid textAlign="end">
                                    <IconButton
                                        className='close-btn'
                                        aria-label="close"
                                        onClick={handleFreeUserDialogClose}
                                        style={{ marginTop: "-10px !important" }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>

                                <Grid marginTop={1}>
                                    <Typography fontWeight="bold" gutterBottom className='share-hiagram-head'>
                                        Share your hiagram
                                    </Typography>
                                    <Typography fontWeight="bold" className='share-hiagram-subhead'>The unique link below can be shared with colleagues - no account required.</Typography>
                                </Grid>


                                {/* {userContext.isPublicAccess === false ?
                                    <DialogContent className='set-link-button'>
                                        <Box className='set-link-button-box'>
                                            <Button className='genrate-link-btn' onClick={genrateHigramLink}>Genrate link</Button>
                                        </Box>
                                    </DialogContent>
                                    :
                                    (isPublicAccessLoading === true ?
                                        <DialogContent className='share-hiagram-loader'>
                                            <CircularProgress />
                                        </DialogContent>
                                        :
                                        <> */}

                                <DialogContent className='share-hiagram-for-free-user'>
                                    <Box className='share-hiagram-free-user-box'>
                                        <FormControl fullWidth className='share-hiagram-free-user'>
                                            <TextField
                                                id="linkbox"
                                                fullWidth
                                                inputRef={linkBoxRef}
                                                onBlur={(e) => setIsCopied(false)}
                                                type='outline'
                                                autoComplete="off"
                                                size='medium'
                                                value={generatedLinkWithLogin}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <Tooltip title={isCopied ? 'Copied' : 'Copy to clipboard'}>
                                                                <IconButton onClick={() => copyLink(generatedLinkWithLogin)}>
                                                                    {isCopied ? <CheckCircleOutlineIcon sx={{ color: '#680001' }} /> : <ContentCopyRoundedIcon />}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                }}
                                            />

                                            {/* <Typography className='copy-btn' onClick={() => copyLink(generatedLink)}>Copy</Typography> */}
                                            {/* <Button className='copy-btn' onClick={() => navigator.clipboard.writeText(generatedLink)}> Copy</Button> */}
                                        </FormControl>

                                        <Box className='share-hiagram-free-user-button'>
                                            <Button variant="contained" className='cancel-btn' onClick={handleFreeUserDialogClose}>Close</Button>
                                        </Box>
                                    </Box>
                                </DialogContent>

                                {/* </>)} */}
                            </Dialog>

                            <Dialog sx={{
                                "& .MuiDialog-paper": { width: "80%", maxHeight: 455 },
                            }}
                                maxWidth="xs"
                                open={isPaidUser}
                                onClose={handleClose}
                                className='share-hiagram-dialog-box'
                            >
                                <Grid textAlign="end">
                                    <IconButton
                                        //sx={{ marginRight: "4px !important", marginTop: "7px !important" }}
                                        className='close-btn'
                                        aria-label="close"
                                        onClick={handleClose}
                                        style={{ marginTop: "-10px !important" }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>

                                <Grid marginTop={2}>
                                    <Typography fontWeight="bold" gutterBottom className='share-hiagram-head'>
                                        Share your hiagram
                                    </Typography>
                                    <Typography fontWeight="bold" className='share-hiagram-subhead'>You can share your hiagram with any other registered users at <span className='color-text'>{userContext.clientOrgName}</span></Typography>
                                </Grid>

                                {/*--For AlertSuccess--*/}
                                {showAlert.isSuccess === true &&
                                    showAlert.type === AlertType.Success ? (
                                    <Grid paddingLeft={4} paddingRight={4} marginTop={2} className="popup-alert">
                                        <Stack sx={{ width: "100%" }}>
                                            {/* <Alert severity="success">{showAlert.message}timeout={5000}</Alert> */}
                                            <Alert severity="success">{showAlert.message}</Alert>
                                        </Stack>
                                    </Grid>
                                ) : showAlert.isSuccess === false &&
                                    showAlert.type === AlertType.Error ? (
                                    <Grid paddingLeft={4} paddingRight={4} marginTop={2} className="popup-alert">
                                        <Stack sx={{ width: "100%" }}>
                                            <Alert severity="error">{showAlert.message}</Alert>
                                        </Stack>
                                    </Grid>
                                ) : (
                                    <></>
                                )}

                                <DialogContent className='share-hiagram-dialog-content'>
                                    <Formik
                                        initialValues={shareFormValue}
                                        validationSchema={validateSchema}
                                        onSubmit={(values: any, { resetForm }) => {
                                            submitForm(values, resetForm);
                                        }}
                                    >
                                        {(props: any) => {
                                            return (
                                                <Form onSubmit={props.handleSubmit}>
                                                    <Box className='share-hiagram-form-box'>
                                                        <FormControl fullWidth className='share-hiagram-user-form'>
                                                            <Autocomplete
                                                                id="name"
                                                                value={null}
                                                                onChange={(event, newValue) => {
                                                                    props.setFieldValue(
                                                                        "name",
                                                                        newValue,
                                                                        true
                                                                    );
                                                                }}
                                                                freeSolo
                                                                selectOnFocus
                                                                handleHomeEndKeys
                                                                onInputChange={(e, value, reason) => {
                                                                    setInputValue(value)
                                                                    findUser(value)
                                                                }}
                                                                inputValue={inputValue}

                                                                options={userValue}
                                                                getOptionLabel={(option: any) => option.name}

                                                                renderOption={(props, option: any) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <Avatar
                                                                            alt="Remy Sharp"
                                                                            //src={`https://s.gravatar.com/avatar/bd4ae38ac8635bd1422d3cdd25483d75?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${option.name.split(":")[1].slice(1, 3)}.png`}
                                                                            //src={`https://i1.wp.com/cdn.auth0.com/avatars/${option.name.split(":")[1].slice(1, 3)}.png?ssl=1`}
                                                                            src={`https://i1.wp.com/cdn.auth0.com/avatars/${option.name.split(" ")[0].slice(0, 1).toLocaleLowerCase() + option.name.split(" ")[1].slice(0, 1).toLocaleLowerCase()}.png?ssl=1`}
                                                                            sx={{ width: 24, height: 24, marginRight: '10px' }}
                                                                        />
                                                                        {option.name}
                                                                    </Box>
                                                                )}
                                                                fullWidth
                                                                loading={dataLoding}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Name"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {dataLoding ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {props.errors.name && props.touched.name && (
                                                                <Typography
                                                                    className={
                                                                        props.errors.name && props.touched.name
                                                                            ? "validation-message text-input error"
                                                                            : "validation-message text-input"
                                                                    }
                                                                >
                                                                    {props.errors.name}
                                                                </Typography>
                                                            )}
                                                        </FormControl>

                                                        <Box className='share-hiagram-button'>
                                                            <Button className='cancel-btn' onClick={handleClose}>Cancel</Button>
                                                            <LoadingButton variant="contained" className='share-btn' type="submit" loading={isBtnLoading}>Share</LoadingButton>
                                                        </Box>
                                                    </Box>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                maxWidth="xs"
                                fullWidth={true}
                                open={showHiagramDialog === 0}
                                // TransitionComponent={Transition}
                                //onClose={handleCloseWelcomeModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className='welcome-dialog-box'
                            >
                                <Box className='welcome-img-box'>
                                    <img loading="lazy" alt="imprend" className='welecome-icon' src={userImage} />
                                </Box>
                                <DialogTitle className='welcome-dialog-title'>Not so fast!</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                        Your hiagram will appear once you add a Team or Project in the interview
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions className="welcome-dialog-action">
                                    <Button
                                        variant='contained'
                                        onClick={() => { navigate("/question/" + userContext.hiagramId) }}
                                        className="welcome-close-btn"
                                    >
                                        Resume interview
                                    </Button>

                                </DialogActions>
                            </Dialog>
                        </>}
                </div>
            </Fade>
        </>
    );
}

